<template>
  <a-modal :visible.sync="showState.show"
           :title="showState.type==='edit'?'编辑配送范围':'新建配送范围'"
           destroyOnClose
           width="30%"
           @ok="handleOk"
           @cancel="handlerCancel">
    <div class="modal-search-bar">
      <a-form-model layout="horizontal"
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    ref="searchRef"
                    :model="addForm">

        <a-form-model-item prop="outlets_id"
                           label="归属网点">
          <!-- <a-select v-if="showState.type === 'add'" v-model="addForm.outlets_id"
                    show-search
                    placeholder="请输入网点名称"
                    :filter-option="false"
                    :not-found-content="null"
                    :default-active-first-option="false"
                    @search="handleSearch">
            <a-select-option v-for="d in outletsList"
                             :key="d.outlets_id">
              {{ d.showName }}
            </a-select-option>
          </a-select>
          <span v-else>{{outletsName}}</span> -->
          <a-select v-model="addForm.outlets_id"
                    show-search
                    placeholder="请输入网点名称"
                    :filter-option="false"
                    :not-found-content="null"
                    :default-active-first-option="false"
                    @search="handleSearch">
            <a-select-option v-for="d in outletsList"
                             :key="d.outlets_id">
              {{ d.showName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="name"
                           label="范围名称">
          <a-input allowClear
                   v-model="addForm.name"
                   placeholder="范围名称"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="freight"
                           label="运费">
          <a-input-number v-model="addForm.freight"
                          placeholder="运费"
                          :step="0.01"></a-input-number>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>

</template>

<script>

import { addRegion, editRegion, getRegionOutletsList } from '@/api/outlets'
import { formatBusinessStatus } from '@/utils/type'

export default {
  props: {
    showState: {
      type: Object,
    }
  },
  data () {
    return {
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      addForm: {
        outlets_id: "",
        name: "",
        freight: 0,
        region: []
      },
      outletsName: undefined,
      outletsList: [],
      isOperate: false
    }
  },
  mounted () {
    this.initData()
    // this.initOutletsList()
  },
  methods: {
    async initOutletsList (outlets_id) {

      if (this.outletsName) {
        getRegionOutletsList({ 
          adcode: this.showState.adcode, 
          outlets_city_adcode: this.showState.outlets_city_adcode, 
          name: this.outletsName 
        }).then(res => {
          if(res.code == 0){
            
            let list = res.data.list.map(el=>{
              const statusName = formatBusinessStatus(el.outlets_status)
              el.showName = statusName ? ("【"+statusName+"】" + el.outlets_name) : el.outlets_name
              return el
            })

            let outletsList = null
            // 先匹配ID
            if(outlets_id){
              outletsList = list.filter(el=> {
                return el.outlets_id == outlets_id 
              })
            }

            // 再匹配名字 模糊搜索
            if(!outletsList || !outletsList.length){
              outletsList = list.filter(el=>{
                return el.outlets_status == 2 || el.outlets_status == 3  // 未发布 和 营业中
              })
            }
            this.outletsList = outletsList
            
            // if (this.showState.type != 'add') {
              if(outletsList.length){
                this.addForm.outlets_id = outletsList[0].outlets_id
              }
            // }
          } 
        })
      }
    },
    async initData () {
      if (this.showState.type === 'edit') {
        this.addForm = this.showState.data
        this.outletsName = this.showState.data.outlets_name
        this.initOutletsList(this.addForm.outlets_id)
      }
      if (this.showState.type === 'add') {
        this.addForm = this.showState.data
        this.addForm.region = this.showState.region
        
        this.outletsName = this.showState.outlets_name
        this.initOutletsList(this.addForm.outlets_id)
      }
    },
    // 保存
    handleOk () {
      this.isOperate = true
      if (this.showState.type === 'edit') {
        let params = {
          id:         this.addForm.id,
          name:       this.addForm.name,
          freight:    this.addForm.freight,
          region:     this.addForm.region,
          outlets_id: this.addForm.outlets_id,
        }
        editRegion(params).then(res => {
          if (res.code === 0) {
            this.$message.success('编辑配送范围成功！')
            this.showState.show = false
            this.$parent.initRegionList()
            this.$emit('ok', res)
          }
        })
      } else {
        let params = {
          name:       this.addForm.name,
          freight:    this.addForm.freight,
          region:     this.addForm.region,
          outlets_id: this.addForm.outlets_id,
        }
        addRegion(params).then(res => {
          if (res.code === 0) {
            this.$message.success('新增配送范围成功！')
            this.showState.show = false
            this.$parent.initRegionList()
            this.$emit('ok', res)
          }
        })
      }
    },

    resetForm () {
      this.$refs.searchRef.resetFields();
    },
    handlerCancel () {
      this.showState.show = false
      this.$emit('cancel', this.isOperate, this.showState)
    },
    handleSearch (val) {
      this.outletsName = val

      this.initOutletsList()
    },
  }
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>