<template>
  <div class="bg-white p-3 flex h-full overflow-hidden">
    <transition name="fade">
      <div style="width:500px"
           v-if="isLeftShow">
        <div class="flex justify-between items-center mb-2">
          <!-- <a-select v-model="formData.adcode" style="width:200px;" placeholder="门店城市"
                    @change="handlerAdcodeChange">
            <a-select-option v-for="(item, index) in cityList"
                   :key="index"
                   :value="item.adcode">{{ item.name }}</a-select-option>
          </a-select> -->
          <div>
            <a-select v-model="formData.outlets_city_adcode" style="width:100px;"
                      @change="handlerManageAdcodeChange">
              <a-select-option v-for="(item, index) in manageCityList"
                     :key="index"
                     :value="item.adcode">{{ item.name }}</a-select-option>
            </a-select>
            <a-select v-model="formData.status" class="ml-2" style="width:130px;"
                      @change="handlerStatusChange">
              <a-select-option value="">未发布/营业中</a-select-option>
              <a-select-option value="3">营业中</a-select-option>
            </a-select>
            <a-select v-model="formData.include_pending" class="ml-2" style="width:150px;"
                      @change="handlerIncludePendingChange">
              <a-select-option value="1">全部</a-select-option>
              <a-select-option value="">已设置配送范围</a-select-option>
            </a-select>
          </div>
          
          <a-button type="primary" @click="handlerAdd">新增范围</a-button>
        </div>
        <div class="flex mb-2">
          <a-input v-model="searchKeyWord"
                   placeholder="地址关键词"
                   @keyup.enter="handlerSearch"></a-input>
          <a-button class="ml-2" type="primary" @click="handlerSearch">搜索</a-button>
        </div>

        <base-table rowKey="tmp_id"
                    :columnsData="columns"
                    :tableData="dataList"
                    :rowSelection="rowSelection"
                    :customRow="Rowclick"
                    :rowClassName="getRowClass">
          <template #operation="{ record }">
            <template v-if="record.id">
              <a @click.stop="handlerEdit(record)">编辑</a>
              <a class="ml-2" type="link"
                 @click.stop="handlerDelete(record)">删除</a>
            </template>
            <template v-else>未配置</template>
          </template>
        </base-table>
      </div>
    </transition>
    <span style="width:20px;margin-top:20%;text-align:center;"
          class="cursor-pointer"
          @click="() => isLeftShow = !isLeftShow">{{ isLeftShow ? '收起' : '展开' }}左侧</span>
    <div class="relative flex-1" style="overflow:hidden;">
      <!-- <div>操作说明：点击新增后，在地图上点击画线，双击画线完毕后输入关键信息保存。</div>
      <div> 点选某一个点，展示配送范围，点击某配送范围，可直接编辑。</div>
      <div>
        绘制 鼠标左键点击及移动即可绘制图形<br />
        结束绘制 鼠标左键双击即可结束绘制折线、多边形、圆形，多边形会自动闭合<br />
        单选 鼠标左键点击图形<br />
        中断 绘制或编辑过程中按下esc键可中断该过程<br />
      </div>-->
      <div id="map-container" class="map-container">
        <div id="shop_map_container" class="map-class"></div>
      </div>
      <div class="tool-control">
        <a-button v-if="isAdd" class="ml-2" type="primary"
                  @click="handlerExit">退出新增</a-button>
        <template v-if="isActive">
          <a-button class="ml-2" :disabled="!this.selectedAreaId"
                    @click="handlerCancel">取消选中</a-button>
          <a-button class="ml-2" type="primary"
                    @click="handlerEditSave">保存</a-button>
          <!-- <a-button class="ml-2"
                    type="primary"
                    @click="handlerExit">退出</a-button> -->
        </template>
      </div>
    </div>
    <add-area-modal v-if="showState.show"
                    :showState.sync="showState"
                    @cancel="handlerAddCancel" @ok="updateComplete" />
  </div>
</template>

<script>
import {
  getCityList,
  getFinanceCityList,
  getRegionList, deleteRegion, editRegion
} from "@/api/outlets"
import BaseTable from '../../components/base-table/base-table.vue'

import { deliveryAreaColumns } from './columns'
import AddAreaModal from './components/add-area-modal.vue'
import _ from 'lodash'
import { getCityLatLng } from "@/utils/tMap.js"
import config from '@/utils/config.js'
import { rowSpan } from "@/utils/index"
import { formatOutletsType } from "@/utils/type"

const TMap = window.TMap

export default {
  components: { BaseTable, AddAreaModal },
  data () {
    return {
      isLeftShow: true,
      isAddShow: false,
      formData: {
        adcode: "",
        outlets_city_adcode: "",
        status: "",
        include_pending: "1",
      },

      columns: deliveryAreaColumns,
      cityList: [],
      manageCityList: [],
      isCityManage: false,
      baseList: [],
      basePendingList: [],
      outletsList: [],
      dataList: [],
      searchKeyWord: "",

      showState: {
        type: 'add',
        show: false,
        adcode: "",
        outlets_city_adcode: "",
        outlets_id: null,
        outlets_name: '',
        data: {},
        region: ''
      },
      shopStyle: {
        'shopMarker': new TMap.MarkerStyle({
          width: 30,
          height: 34,
          src: require("../../assets/icon-shop.png"), //图片路径
          anchor: { x: 15, y: 34 },
        }),
        'duduMarker': new TMap.MarkerStyle({
          width: 30,
          height: 39,
          src: require("../../assets/icon-peisong.png"), //图片路径
          anchor: { x: 15, y: 39 },
        }),
      },
      iconInfo: { // 编辑器的icon样式
        virtual: { // 改变虚点icon的样式
          src: require('../../assets/point1.png'), // 编辑点图片url或base64地址，若为url地址图片一定要在服务器端配置允许跨域
          width: 20, // 图片的宽度，默认为10px
          height: 20, // 图片的高度，默认为10px
        },
        actual: { // 改变实点icon的样式
          src: require('../../assets/point2.png'),
          width: 30,
          height: 30,
        }
      },
      markerList: [],
      // 地图操作对象
      map: null,
      editor: null,
      addEditor: null,
      marker: null,

      selectedAreaId: null,
      isActive: false, // 是否激活
      isAdd: false,  // 是否新增区域
      isReDraw: false,  // 是否重绘
      activeRegion: null,  // 当前激活图层
      activeMarker: null, // 点击激活点
      multipolygon: null,  // 存放图层
      editableOverlay: null,
      infoWindow: null, //信息窗口

      selectRows: [],
      rowSelection: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection.selectedRowKeys.unshift(record.tmp_id)
            this.selectRows.unshift(record)
            this.addDrawArea(record)
          } else {
            this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
              (key) => key != record.tmp_id
            )
            this.selectRows = this.selectRows.filter(el => el.tmp_id != record.tmp_id)
            if(record.id){
              this.deleteDrawArea(record.id)
            }
          }
        },
        onSelectAll: (selected, selectedRows) => {
          if (selected) {
            // 清除图层内容
            if (this.multipolygon) {
              this.multipolygon.remove(this.rowSelection.selectedRowKeys)
              this.multipolygon.setMap(null)
              this.multipolygon = null
            }
            if (this.editor) {
              this.editor.setMap(null)
              this.editor = null
            }
            if (this.addEditor) {
              this.addEditor.setMap(null)
              this.addEditor = null
            }
            this.selectedAreaId = null
            // 全选
            selectedRows.map(el => {
              el.checked = true
            })
            this.selectRows = selectedRows
            this.rowSelection.selectedRowKeys = selectedRows.map(el => el.tmp_id)
            this.drawArea()
            this.handlerStartDraw()
          } else {
            // 清除图层内容
            if (this.multipolygon) {
              this.multipolygon.remove(this.rowSelection.selectedRowKeys)
              this.multipolygon.setMap(null)
              this.multipolygon = null
            }
            if (this.editor) {
              this.editor.setMap(null)
              this.editor = null
            }
            if (this.addEditor) {
              this.addEditor.setMap(null)
              this.addEditor = null
            }
            this.selectedAreaId = null
            // 反选
            this.dataList.map(el => {
              el.checked = false
            })
            this.selectRows = []
            this.rowSelection.selectedRowKeys = []
          }
        },
      },
    }
  },
  async mounted () {
    await this.initFinanceCityList()
    this.handlerManageAdcodeChange()
  },
  methods: {
    // 对外的城市列表
    async initCityList () {
      const { data, code } = await getCityList()
      if (code == 0) {
        this.cityList = data.list
      }
    },
    // 内部管理的城市列表
    async initFinanceCityList () {
      const list = this.$store.getters['authCityList']
      // 如果是城市市场账号
      if(list && list.length) {
        this.isCityManage = true
        this.manageCityList = list
      }else{
      // 否则全部城市
        const { code, data } = await getFinanceCityList()
        if (code == 0) {
          this.manageCityList = data.list
        }
      }
      this.formData.outlets_city_adcode = this.manageCityList[0].adcode
    },

    // 初始化城市数据、地图
    async initAndRender(){
      // 重置状态、关闭内容
      if(this.marker){
        this.marker.setMap(null)
      }
      
      // 获取新数据
      await this.refreshRegion()
      // 初始化地图
      await this.initMap()
      // 给地图添加网点icon
      this.initMarkerList()
    },

    // 刷新区域数据
    async refreshRegion () {
      console.log("refreshRegion")

      // 重置状态、关闭内容
      this.markerList = []
      this.selectRows = []
      this.rowSelection.selectedRowKeys = []
      this.isActive = false
      this.selectedAreaId = null

      if(this.infoWindow){
        this.infoWindow.close();
      }
      if (this.multipolygon) {
        this.multipolygon.setMap(null)
        this.multipolygon = null
      }
      if (this.editor) {
        this.editor.setMap(null)
        this.editor = null
      }
      if (this.addEditor) {
        this.addEditor.setMap(null)
        this.addEditor = null
      }
      
      // 请求新数据
      const { data, code } = await getRegionList(this.formData)
      if (code == 0) {
        this.baseList = data.list
        this.basePendingList = data.pending_list

        let dataList = data.list.concat(data.pending_list).map(item=>{
          item.type = formatOutletsType(item.outlets_type)
          if(item.id){
            item.tmp_id = item.outlets_id+"-"+item.id
          }else{
            item.tmp_id = item.outlets_id
          }
          return item
        })
        this.dataList = rowSpan(dataList, "outlets_id")
      }
    },

    initMarkerList(){
      // 生成marker数据
      let markerList = []
      this.dataList.map(item => {
        let marker = markerList.find(el=>el.outlets_id==item.outlets_id)
        if(!marker){
          marker = {
            // "id": item.id,
            // "tmp_id": item.tmp_id,
            "styleId": item.outlets_type === 1 ? 'shopMarker' : 'duduMarker',
            "outlets_id": item.outlets_id,
            "outlets_name": item.outlets_name,
            "position": new TMap.LatLng(item.latitude, item.longitude),
            "properties": {
              "title": item.outlets_name,
              "address": item.outlets_address,
              "region_ids": []
            }
          }
          markerList.push(marker)
        }
        if(item.id){
          marker.properties.region_ids.push(item.id)
        }
      })
      this.markerList = markerList

      // 渲染显示
      if(this.map){
        if(this.marker){
          this.marker.setMap(null)
        }
        this.marker = new TMap.MultiMarker({
          id: "shop_map_container_layer",
          map: this.map,
          styles: this.shopStyle,
          geometries: this.markerList,
          enableBubble: false,
        })

        // 监听标注点击事件
        const _this = this
        this.marker.on("click", function (evt) {
          evt.originalEvent.stopPropagation()
          _this.popInfoWindow(evt.geometry)
        })

        // 把所有marker囊括到地图可视区
        let bounds = new TMap.LatLngBounds();
        this.markerList.forEach(function (item) {
          if (bounds.isEmpty() || !bounds.contains(item.position)) {
            bounds.extend(item.position);
          }
        })
        // 设置地图可视范围
        this.map.fitBounds(bounds, {
          padding: 40 // 自适应边距
        });
      }
    },

    handlerSearch () {
      let dataList = this.baseList.concat(this.basePendingList)
      if (this.searchKeyWord) {
        dataList = dataList.filter(item => {
          return (item.name||"").includes(this.searchKeyWord) || item.outlets_address.includes(this.searchKeyWord) || item.outlets_name.includes(this.searchKeyWord)
        })
      }
      this.dataList = rowSpan(dataList, "outlets_id")
    },

    handlerAdcodeChange (adcode) {
      this.showState.adcode = adcode
      this.initAndRender()
    },
    async handlerManageAdcodeChange () {
      if(this.formData.outlets_city_adcode){
        // 这句没用吧
        this.showState.outlets_city_adcode = this.formData.outlets_city_adcode
        this.initAndRender()
      }
    },
    async handlerStatusChange(){
      await this.refreshRegion()
      this.initMarkerList()
    },
    async handlerIncludePendingChange(){
      await this.refreshRegion()
      this.initMarkerList()
    },

    //新增区域
    handlerAdd () {
      this.startAddRegion()
    },

    startAddRegion(outletInfo){
      this.$message.info('请在地图上鼠标左键单击绘制，双击完成')

      this.isAdd = true

      // test 这个什么作用
      if (this.editor) {
        this.editor.setMap(null)
      }
      if (this.multipolygon) {
        this.multipolygon.setMap(null)
      }

      // test 这个什么作用
      this.showState.type = 'add'

      // todo 什么作用？如果不为空呢？
      // if (!this.multipolygon) {
      //   this.multipolygon = new TMap.MultiPolygon({
      //     map: this.map,
      //     enableBubble: false,
      //   })
      // }
      let addPolygon = new TMap.MultiPolygon({
        map: this.map,
        enableBubble: false,
      })

      let addEditor = new TMap.tools.GeometryEditor({
        map: this.map, // 编辑器绑定的地图对象
        overlayList: [ // 可编辑图层
          {
            overlay: addPolygon,
            id: 'addPolygon',
            selectedStyleId: 'highlight'
          },
        ],
        actionMode: TMap.tools.constants.EDITOR_ACTION.DRAW, // 编辑器的工作模式
        activeOverlayId: 'addPolygon', // 激活图层
        selectable: true, // 开启点选功能
        snappable: true, // 开启吸附
        enableBubble: false,
      });

      // 监听绘制结束事件，获取绘制几何图形
      addEditor.on('draw_complete', (geometry) => {
        const regions = geometry.paths.map(item => {
          return item.lng + ' ' + item.lat
        }).join(',')
        this.showState.region = regions
        this.showState.show = true
        this.showState.addId = geometry.id

        if(outletInfo){
          this.showState.outlets_id = outletInfo.outlets_id
          this.showState.outlets_name = outletInfo.outlets_name
          this.showState.data = {
            outlets_id: outletInfo.outlets_id,
            outlets_name: outletInfo.outlets_name,
          }
        }else{
          this.showState.outlets_id = ""
          this.showState.outlets_name = ""
          this.showState.data = {}
        }

        addEditor.stop()
        addEditor.disable()
        addEditor.setMap(null)
      });

      // 激活新增图层（什么作用）
      addEditor.setActiveOverlay('addPolygon');

      this.multipolygon = addPolygon
      this.addEditor = addEditor
    },

    handlerEdit (row) {
      this.showState.data = row
      this.showState.type = 'edit'
      this.showState.show = true
    },

    updateComplete(){
      // this.handlerAdcodeChange(this.showState.adcode)

      // this.render()
      this.refreshRegion()
    },

    handlerDelete (row) {
      const _this = this
      this.$confirm({
        title: '提示',
        content: '是否确认删除该范围?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          deleteRegion({ id: row.id, status: -1 }).then(res => {
            if (res.code === 0) {
              _this.$message.success('删除成功')
              _this.deleteDrawArea(row.id)
              if(_this.editor){
                _this.editor.stop()
              }
              _this.marker.setVisible(true)
              _this.isActive = false
              _this.selectedAreaId = null
              _this.refreshRegion()
            }
          })  
        },
        onCancel () {
          console.log('取消删除');
        },
      });
    },

    // 开启图层编辑
    handlerStartDraw () {
      const _this = this
      _this.editor = new TMap.tools.GeometryEditor({
        map: this.map, // 编辑器绑定的地图对象
        overlayList: [ // 可编辑图层
          {
            overlay: _this.multipolygon,
            id: 'regionPolygon',
            selectedStyleId: 'highlight'
          },
        ],
        actionMode: TMap.tools.constants.EDITOR_ACTION.INTERACT, // 编辑器的工作模式
        activeOverlayId: 'regionPolygon', // 激活图层
        selectable: true, // 开启点选功能
        snappable: true, // 开启吸附
        iconInfo: _this.iconInfo,
        enableBubble: false,
      });

      if (this.selectedAreaId) {
        this.editor.select(['multipolygon'])
      }

      // 监听选中图层
      _this.editor.on("select", () => {
        let arr = Array.from(_this.editor._selectedSet._c)
        if(arr.length){
          _this.isActive = true
          _this.activeRegion = arr[0]
          _this.selectedAreaId = _this.activeRegion.tmp_id
          _this.marker.setVisible(false)
        }
      })

      // 监听修改完成
      _this.editor.on("adjust_complete", (datas) => {
        this.activeRegion = datas

        const item = this.selectRows.find(el => el.tmp_id === datas.tmp_id)
        const index = this.selectRows.findIndex(el => el.tmp_id === datas.tmp_id)
        item.region = datas.paths.map(item => {
          return item.lng + ' ' + item.lat
        }).join(',')
        item.isUpdate = true
        this.selectRows[index] = item
      })

      // 监听新增完成
      _this.editor.on("draw_complete", function (points) {
        // 如果是重绘则不用弹窗
        _this.isReDraw = false
        _this.activeRegion.paths = points.paths
        const item = _this.selectRows.find(el => el.tmp_id === _this.selectedAreaId)
        const index = _this.selectRows.findIndex(el => el.tmp_id === _this.selectedAreaId)

        item.region = points.paths.map(item => {
          return item.lng + ' ' + item.lat
        }).join(',')
        item.isUpdate = true

        _this.selectRows[index] = item

        _this.editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.INTERACT);
        _this.editor.stop()
      })
    },

    // 选中某一区域重绘，删除原图层开启绘制功能
    handlerReDraw () {
      if (this.selectedAreaId) {
        this.isReDraw = true
        this.editor.delete()
        // 激活编辑图层
        this.editor.setActionMode(TMap.tools.constants.EDITOR_ACTION.DRAW);
        this.editor.setActiveOverlay('regionPolygon');
      } else {
        this.$message.warning('请先选择某一范围再进行操作')
      }
    },
    // 预览
    handlerPrView () {
      this.handlerExitEditMode()
      this.marker.setVisible(true)
    },
    handlerExitEditMode(){
      this.selectedAreaId = null
      if (this.editor) {
        this.editor.stop()
      }
      if (this.addEditor && this.addEditor.map) {
        this.addEditor.stop()
      }
    },

    // 退出
    handlerExit () {
      this.isAdd = false

      if(this.addEditor){
        this.addEditor.stop()
        this.addEditor.disable()
        this.addEditor.setMap(null)
        this.addEditor = null
      }
      if (this.multipolygon) {
        this.multipolygon.setMap(null)
        this.multipolygon = null
      }
    },

    // 新增窗口关闭
    handlerAddCancel (flag, state) {
      this.isAdd = false
      // if (flag) {
      // this.refreshRegion()
      // } else {
      // 没有保存 直接关闭了窗口，那么清空当前新增的图层
      if (this.addEditor) {
        this.multipolygon.remove([state.addId])
      }
      // }
    },

    // 保存当前编辑绘制
    handlerEditSave () {
      this.isAdd = false

      if (this.selectRows.length > 0) {
        let proArr = []
        this.selectRows.map(item => {
          if (item.isUpdate) {
            proArr.push(this.asyncAreaInfo(item))
          }
        })
        Promise.all(proArr).then(res => {
          if (res.length > 0) {
            this.$message.success('更新范围成功')
            this.editor.stop()
            this.marker.setVisible(true)
            this.isActive = false
            this.selectedAreaId = null
            this.refreshRegion()
          }
        })
      }
    },

    // 调用接口 更新范围数据
    asyncAreaInfo (rows) {
      return new Promise((resolve, reject) => {
        editRegion(rows).then(res => {
          if (res.code === 0) {
            resolve(res)
          } else {
            reject()
          }
        })
      });
    },

    async initMap () {
      // 计算地图中心点
      let center_lat, center_lng
      if (this.dataList.length > 0) { // 有网点列表：地图就囊括所有网点
        let totalLat = 0
        let totalLng = 0
        this.dataList.map(d => {
          totalLat += d.latitude
          totalLng += d.longitude
        })
        center_lat = totalLat / this.dataList.length
        center_lng = totalLng / this.dataList.length
      } else { // 没有网点列表：通过城市定位显示地图
        // const { status, result } = await getCityLatLng(this.formData.adcode)
        const { status, result } = await getCityLatLng(this.formData.outlets_city_adcode)
        if (status == 0 && result[0].length) {
          let totalLat = 0
          let totalLng = 0
          result[0].map(d => {
            totalLat += d.location.lat
            totalLng += d.location.lng
          })
          center_lat = totalLat / result[0].length
          center_lng = totalLng / result[0].length
        }else{
          // 获取电脑的定位信息
          // this.getLoLa()
          // 默认定位
          center_lat = 39.908823
          center_lng = 116.39747
        }
      }
      // 设置中心点坐标
      let center = new TMap.LatLng(center_lat, center_lng)

      // 初始化地图
      if (!this.map) {
        this.map = new TMap.Map(
          document.getElementById("shop_map_container"),
          {
            center: center,
            zoom: 12,
          }
        )
      }
      // 移动到中心点
      this.map.panTo(center)

      // 初始化infoWindow*********************************
      if(!this.infoWindow){
        this.infoWindow = new TMap.InfoWindow({
          map: this.map,
          position: new TMap.LatLng(center_lat, center_lng),
          offset: { x: 0, y: -32 } //设置信息窗相对position偏移像素
        });
        this.infoWindow.close();//初始关闭信息窗关
      }
    },

    popInfoWindow(geometry, outlets_id){
      console.log("popInfoWindow", geometry, outlets_id)
      if(this.infoWindow){
        this.infoWindow.close();
      }
      
      if(!geometry){
        if(outlets_id){
          geometry = this.markerList.find(m=>m.outlets_id==outlets_id)
        }
      }
      if(!geometry) return

      const _this = this
      _this.activeMarker = geometry
      _this.showState.outlets_id = geometry.outlets_id

      //设置infoWindow
      _this.infoWindow.open(); //打开信息窗
      _this.infoWindow.setPosition(geometry.position);//设置信息窗位置

      const hasRegions = geometry.properties.region_ids && geometry.properties.region_ids.length
      _this.infoWindow.setContent(`
          <div style="text-align: left;white-space: normal;max-width: 350px;">
            <div><b>${geometry.properties.title.toString()}</b></div>
            <div>${geometry.properties.address.toString()}</div>
            <div style="margin-top:10px;" >
              <a id="activePoiAdd" onclick="_mapAddPoiRegion()">新增范围</a>
              <a id="activePoiLook" style="${hasRegions?'':'display:none;'}" onclick="_mapShowPoiRegion()" class="ml-2">查看范围</a>
            </div>
          </div>`);

      // 点击新增区域范围按钮事件处理
      // document.getElementById('activePoiAdd').addEventListener('click', () => {
      window._mapAddPoiRegion = (() => {
        _this.infoWindow.close();

        _this.startAddRegion({
          outlets_id: geometry.outlets_id,
          outlets_name: geometry.outlets_name,
        })
      })
      
      // 点击查看某网点的配送范围
      // document.getElementById('activePoiLook_'+geometry.id).addEventListener('click', () => {
      window._mapShowPoiRegion = ((e) => {
        console.log("_mapShowPoiRegion", e)

        _this.infoWindow.close();
        _this.isActive = false
        if (_this.editor) {
          _this.editor.setMap(null)
        }
        if (_this.multipolygon) {
          _this.selectRows = []
          _this.rowSelection.selectedRowKeys = []
          _this.multipolygon.setGeometries([])
        }

        const outlets_id = geometry.outlets_id
        
        _this.selectRows = _this.dataList.filter(item=>{
          return item.outlets_id == outlets_id
        }).map(el=>{
          el.checked = true
          return el
        })
        _this.rowSelection.selectedRowKeys = geometry.properties.region_ids

        _this.drawArea()
        _this.handlerStartDraw()
      })
    },

    //********************* 地图上区域操作部分   ************************************************ */
    // 将所有选中行的区域画到地图上
    drawArea () {
      const _this = this
      let regionList = []
      this.selectRows.map(el => {
        console.log("drawArea map", el)

        if(!el.id) return
        let paths = []
        el.region.split(',').map(item => {
          paths.push(new TMap.LatLng(item.split(' ')[1], item.split(' ')[0]))
        })
        regionList.push({
          tmp_id: el.tmp_id,
          id: el.id,
          outlets_id: el.outlets_id,
          name: el.name,
          freight: el.freight,
          paths: paths,
          styleId: 'normal'
        })
      })

      if (_this.multipolygon) {
        _this.multipolygon.setGeometries(regionList)
      } else {
        _this.multipolygon = new TMap.MultiPolygon({
          map: this.map,
          id: 'multipolygon',
          styles: {
            'normal': new TMap.PolygonStyle({
              color: 'rgba(0, 0, 255, 0.3)'
            }),
            'selected': new TMap.PolygonStyle({
              color: 'rgba(0,255,193,0.6)'
            }),
            'highlight': new TMap.PolygonStyle({
              color: 'rgba(255, 255, 0, 0.6)'
            })
          },
          geometries: regionList,
          selectedStyleId: 'highlight',
          enableBubble: false,
        })
        
        _this.multipolygon.on('click', _this.handlerAreaClick)
      }
    },

    // 添加区域
    addDrawArea (el) {
      console.log("addDrawArea", el)
      if(!el.id) return

      let paths = []
      el.region.split(',').map(item => {
        paths.push(new TMap.LatLng(item.split(' ')[1], item.split(' ')[0]))
      })
      let geometries = {
        tmp_id: el.tmp_id,
        id: el.id,
        outlets_id: el.outlets_id,
        name: el.name,
        freight: el.freight,
        paths: paths,
        styleId: 'normal'
      }
      if (this.multipolygon) {
        this.multipolygon.updateGeometries([geometries])
      } else {
        this.multipolygon = new TMap.MultiPolygon({
          map: this.map,
          id: 'multipolygon',
          styles: {
            'normal': new TMap.PolygonStyle({
              color: 'rgba(0, 0, 255, 0.3)'
            }),
            'selected': new TMap.PolygonStyle({
              color: 'rgba(0,255,193,0.6)'
            }),
            'highlight': new TMap.PolygonStyle({
              color: 'rgba(255, 255, 0, 0.6)'
            })
          },
          geometries: [geometries],
          selectedStyleId: 'highlight',
          enableBubble: false,
        })

        this.multipolygon.on('click', this.handlerAreaClick)

        this.handlerStartDraw()
      }

      //初始化  将所有的点展示到范围内***********************************************************
      var bounds = new TMap.LatLngBounds();
      //设置自适应显示marker
      //判断标注点是否在范围内
      paths.forEach(function (item) {
        //若坐标点不在范围内，扩大bounds范围
        if (bounds.isEmpty() || !bounds.contains(item)) {
          bounds.extend(item);
        }
      })
      //设置地图可视范围
      this.map.fitBounds(bounds, {
        padding: 100 // 自适应边距
      });
    },

    // 从地图上删除某区域ID为左侧table ID
    deleteDrawArea (id) {
      if (this.multipolygon) {
        this.multipolygon.remove([id])
      }

      // 如果要删除的是当前选中正在编辑的图层，则同时删除编辑图层
      if (this.activeRegion && this.activeRegion.id === id) {
        this.editor.delete()
        this.isActive = false
        this.activeRegion = null
      }
      if (this.selectedAreaId === id) {
        this.isActive = false
        this.selectedAreaId = null
      }
    },

    // 点击地图上的区域执行事件
    handlerAreaClick (e) {
      e.originalEvent.stopPropagation()
      
      let regionList = []
      this.selectRows.map(el => {
        if(!el.id) return

        let paths = []
        el.region.split(',').map(item => {
          paths.push(new TMap.LatLng(item.split(' ')[1], item.split(' ')[0]))
        })
        regionList.push({
          tmp_id: el.tmp_id,
          id: el.id,
          outlets_id: el.outlets_id,
          name: el.name,
          freight: el.freight,
          paths: paths,
          styleId: el.id === e.geometry.id ? 'selected' : 'normal'
        })
      })

      this.multipolygon.updateGeometries(regionList)
      this.selectedAreaId = e.geometry.tmp_id
      this.isActive = true
      this.$forceUpdate()

      if (this.infoWindow) {
        this.infoWindow.close();
      }
      this.popInfoWindow(null, e.geometry.outlets_id)

      if (this.editor) {
        this.editor.enable()
      }
    },

    handlerCancel(){
      if (!this.isReDraw && this.showState.type != 'add') {
        this.handlerPrView()
      }
      if (this.selectedAreaId) {
        this.handlerPrView()
      }
    },

    // 行点击
    Rowclick (record) {
      return {
        on: {
          click: () => {
            if (record.checked) {
              this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(key => key != record.tmp_id)
              this.selectRows = this.selectRows.filter(el => el.tmp_id != record.tmp_id)
              record.checked = false
              this.deleteDrawArea(record.id)
            } else {
              this.popInfoWindow(null, record.outlets_id)

              this.rowSelection.selectedRowKeys.push(record.tmp_id)
              this.selectRows.push(_.cloneDeep(record))
              record.checked = true
              // this.drawArea()
              this.addDrawArea(record)
            }
          },
        },
      }
    },


    getRowClass (record) {
      if (record.tmp_id && record.tmp_id == this.selectedAreaId) {
        return 'active-status'
      } else {
        return ''
      }
    },

    getLoLa () {
      var geolocation = new window.qq.maps.Geolocation(
        config.MAP_KEY,
        "HLL-ADMIN"
      )
      geolocation.getLocation((res) => {
        this.initMap(res.lat, res.lng)
      }, () => {
        this.initMap(39.908823, 116.39747)
      })
    },
  },

}
</script>
<style lang="less">
.active-status {
  // background: rgba(255, 255, 0, 0.308) !important;
  td {
    // background: rgba(78, 78, 20, 0.308) !important;
    background: rgba(255, 255, 0, 0.308) !important;
  }
}
</style>
<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  // opacity: 0;
  transform: translateX(-520px) 0.5;
}
.map-container {
  background-color: #f2f2f2;
  // width: 100%;
  flex: 1 0 0;
  // height: 83vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  .map-lola {
    height: 35px;
    display: flex;
  }
  .map-class {
    width: 100%;
    // height: 83vh;
    height: 100%;
  }
}
.tool-control {
  position: absolute;
  bottom: 5px;
  right: 10px;
  z-index: 1000;
}
</style>